import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Link from "../components/Link";
import { graphql } from "gatsby";

const useStyles = makeStyles({
  mainRoot: {
    background: "#09333F",
    backgroundSize: "100% 100%",
    animation: "$gradient 8s ease infinite",
    color: "#fff",
  },
  "@keyframes gradient": {
    "0%": {
      backgroundPosition: "0% 50%",
    },
    "50%": {
      backgroundPosition: "100% 50%",
    },
    "100%": {
      backgroundPosition: "0% 50%",
    },
  },
  root: {
    marginTop: "2rem",
    marginBottom: "2rem",
  },
  marginBottom: {
    marginBottom: "2rem",
  },
  marginBottomBold: {
    marginBottom: "2rem",
    fontWeight: "bold",
  },
  bold: {
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  link: {
    color: "#abcdee",
    cursor: "pointer",
  },
  gridTypo: {
    paddingRight: "1rem",
    paddingLeft: "1rem",
  },
});

export default function Datenschutz() {
  const classes = useStyles();
  return (
    <div className={classes.mainRoot}>
      <Navbar />
      <Grid container className={classes.root}>
        <Grid item xs={1} sm={2} md={2} />
        <Grid item xs={12} md={8} className={classes.gridTypo}>
          <Typography variant="h5" className={classes.marginBottomBold}>
            DATENSCHUTZHINWEISE
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben. <br />
            <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich.
            <br />
            <br /> Der Nutzung von im Rahmen der Impressumspflicht
            veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht
            ausdrücklich angeforderter Werbung und Informationsmaterialien wird
            hiermit ausdrücklich widersprochen. Die Betreiber der Seiten
            behalten sich ausdrücklich rechtliche Schritte im Falle der
            unverlangten Zusendung von Werbeinformationen, etwa durch
            Spam-Mails, vor.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Datenschutzerklärung für die Nutzung von Facebook-Plugins
            (Like-Button)
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook
            (Facebook Inc., 1601 Willow Road, Menlo Park, California, 94025,
            USA) integriert. Die Facebook-Plugins erkennen Sie an dem
            Facebook-Logo oder dem "Like-Button" ("Gefällt mir") auf unserer
            Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier:{" "}
            <Link
              href="https://developers.facebook.com/docs/plugins/"
              external
              className={classes.link}
            >
              https://developers.facebook.com/docs/plugins/
            </Link>
            . Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte
            Verbindung zwischen Ihrem Browser und dem Facebook-Server
            hergestellt. Facebook erhält dadurch die Information, dass Sie mit
            Ihrer IP-Adresse unsere Seite besucht haben. Wenn Sie den Facebook
            "Like-Button" anklicken während Sie in Ihrem Facebook-Account
            eingeloggt sind, können Sie die Inhalte unserer Seiten auf Ihrem
            Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer
            Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir
            als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten
            Daten sowie deren Nutzung durch Facebook erhalten. Weitere
            Informationen hierzu finden Sie in der Datenschutzerklärung von
            facebook unter:
            <br />
            <Link
              href="https://de-de.facebook.com/policy.php"
              external
              className={classes.link}
            >
              https://de-de.facebook.com/policy.php
            </Link>
            <br />
            <br />
            Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten
            Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte aus
            Ihrem Facebook-Benutzerkonto aus.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Datenschutzerklärung für die Nutzung von Google Analytics
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Diese Website benutzt Google Analytics, einen Webanalysedienst der
            Google Inc. ("Google"). Google Analytics verwendet sog. "Cookies",
            Textdateien, die auf Ihrem Computer gespeichert werden und die eine
            Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
            den Cookie erzeugten Informationen über Ihre Benutzung dieser
            Website werden in der Regel an einen Server von Google in den USA
            übertragen und dort gespeichert. Im Falle der Aktivierung der
            IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von
            Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union
            oder in anderen Vertragsstaaten des Abkommens über den Europäischen
            Wirtschaftsraum zuvor gekürzt.
            <br />
            <br /> Nur in Ausnahmefällen wird die volle IP-Adresse an einen
            Server von Google in den USA übertragen und dort gekürzt. Im Auftrag
            des Betreibers dieser Website wird Google diese Informationen
            benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über
            die Websiteaktivitäten zusammenzustellen und um weitere mit der
            Websitenutzung und der Internetnutzung verbundene Dienstleistungen
            gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von
            Google Analytics von Ihrem Browser übermittelte IP-Adresse wird
            nicht mit anderen Daten von Google zusammengeführt.
            <br />
            <br /> Sie können die Speicherung der Cookies durch eine
            entsprechende Einstellung Ihrer Browser-Software verhindern; wir
            weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls
            nicht sämtliche Funktionen dieser Website vollumfänglich werden
            nutzen können. Sie können darüber hinaus die Erfassung der durch das
            Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten
            (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
            Daten durch Google verhindern, indem sie das unter dem folgenden
            Link verfügbare Browser-Plugin herunterladen und installieren:
            <Link
              href="https://tools.google.com/dlpage/gaoptout?hl=de"
              external
              className={classes.link}
            >
              <br />
              https://tools.google.com/dlpage/gaoptout?hl=de
            </Link>
            . <br />
            <br />
            Sie können die Erfassung durch Google Analytics verhindern, indem
            Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie gesetzt,
            der die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website
            verhindert: <br />
            <br />
            Google Analytics deaktivieren <br />
            <br />
            Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden
            Sie unter:{" "}
            <Link
              href="https://www.google.com/analytics/terms/de.htmlbzw"
              external
              className={classes.link}
            >
              https://www.google.com/analytics/terms/de.htmlbzw
            </Link>
            <br /> unter:{" "}
            <Link
              href="https://www.google.com/intl/de/analytics/privacyoverview.html"
              external
              className={classes.link}
            >
              https://www.google.com/intl/de/analytics/privacyoverview.html
            </Link>
            <br />
            Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics
            um den Code „gat._anonymizeIp();“ erweitert wurde, um eine
            anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu
            gewährleisten. <br />
            <br />
            Wir nutzen Google Analytics zudem dazu, Daten aus AdWords und dem
            Double-Click-Cookie zu statistischen Zwecken auszuwerten. Sollten
            Sie dies nicht wünschen, können Sie dies über den
            Anzeigenvorgaben-Manager{" "}
            <Link
              href="https://www.google.com/settings/ads/onweb/?hl=de"
              external
              className={classes.link}
            >
              https://www.google.com/settings/ads/onweb/?hl=de
            </Link>{" "}
            deaktivieren.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Datenschutzerklärung für die Nutzung von Google Adsense
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von
            Werbeanzeigen der Google Inc. ("Google"). Google AdSense verwendet
            sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert
            werden und die eine Analyse der Benutzung der Website ermöglicht.
            Google AdSense verwendet auch so genannte Web Beacons (unsichtbare
            Grafiken). Durch diese Web Beacons können Informationen wie der
            Besucherverkehr auf diesen Seiten ausgewertet werden.
            <br />
            <br /> Die durch Cookies und Web Beacons erzeugten Informationen
            über die Benutzung dieser Website (einschließlich Ihrer IP-Adresse)
            und Auslieferung von Werbeformaten werden an einen Server von Google
            in den USA übertragen und dort gespeichert. Diese Informationen
            können von Google an Vertragspartner von Google weiter gegeben
            werden. Google wird Ihre IP-Adresse jedoch nicht mit anderen von
            Ihnen gespeicherten Daten zusammenführen. <br />
            <br />
            Sie können die Installation der Cookies durch eine entsprechende
            Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
            darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
            Funktionen dieser Website voll umfänglich nutzen können. Durch die
            Nutzung dieser Website erklären Sie sich mit der Bearbeitung der
            über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art
            und Weise und zu dem zuvor benannten Zweck einverstanden.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Datenschutzerklärung für die Nutzung von Google +1
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Erfassung und Weitergabe von Informationen: Mithilfe der Google
            +1-Schaltfläche können Sie Informationen weltweit veröffentlichen.
            über die Google +1-Schaltfläche erhalten Sie und andere Nutzer
            personalisierte Inhalte von Google und unseren Partnern. Google
            speichert sowohl die Information, dass Sie für einen Inhalt +1
            gegeben haben, als auch Informationen über die Seite, die Sie beim
            Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen
            mit Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in
            Suchergebnissen oder in Ihrem Google-Profil, oder an anderen Stellen
            auf Websites und Anzeigen im Internet eingeblendet werden. Google
            zeichnet Informationen über Ihre +1-Aktivitäten auf, um die
            Google-Dienste für Sie und andere zu verbessern. Um die Google
            +1-Schaltfläche verwenden zu können, benötigen Sie ein weltweit
            sichtbares, öffentliches Google-Profil, das zumindest den für das
            Profil gewählten Namen enthalten muss. Dieser Name wird in allen
            Google-Diensten verwendet. In manchen Fällen kann dieser Name auch
            einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten über
            Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils
            kann Nutzern angezeigt werden, die Ihre E-Mail-Adresse kennen oder
            über andere identifizierende Informationen von Ihnen verfügen.
            <br />
            <br />
            Verwendung der erfassten Informationen: Neben den oben erläuterten
            Verwendungszwecken werden die von Ihnen bereitgestellten
            Informationen gemäß den geltenden Google-Datenschutzbestimmungen
            genutzt. Google veröffentlicht möglicherweise zusammengefasste
            Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an
            Nutzer und Partner weiter, wie etwa Publisher, Inserenten oder
            verbundene Websites.
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Datenschutzerklärung für die Nutzung von Twitter
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden.
            Diese Funktionen werden angeboten durch die Twitter Inc., Twitter,
            Inc. 1355 Market St, Suite 900, San Francisco, CA 94103, USA. Durch
            das Benutzen von Twitter und der Funktion "Re-Tweet" werden die von
            Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und
            anderen Nutzern bekannt gegeben. Dabei werden auch Daten an Twitter
            übertragen.
            <br />
            <br /> Wir weisen darauf hin, dass wir als Anbieter der Seiten keine
            Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung
            durch Twitter erhalten. Weitere Informationen hierzu finden Sie in
            der Datenschutzerklärung von Twitter unter{" "}
            <Link
              href="https://twitter.com/privacy"
              external
              className={classes.link}
            >
              https://twitter.com/privacy
            </Link>
            .
            <br />
            <br /> Ihre Datenschutzeinstellungen bei Twitter können Sie in den
            Konto-Einstellungen unter{" "}
            <Link
              href="https://twitter.com/account/settings"
              external
              className={classes.link}
            >
              https://twitter.com/account/settings
            </Link>{" "}
            ändern. <br />
            <br />
            Quelle:{" "}
            <Link
              href="https://www.e-recht24.de/impressum-generator.html"
              external
              className={classes.link}
            >
              https://www.e-recht24.de/impressum-generator.html
            </Link>
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Hosting
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
          </Typography>
          <Typography variant="h5" className={classes.bold}>
            Externes Hosting
          </Typography>
          <Typography variant="body1" className={classes.marginBottom}>
            Diese Website wird extern gehostet. Die personenbezogenen Daten, die
            auf dieser Website erfasst werden, werden auf den Servern des
            Hosters / der Hoster gespeichert. Hierbei kann es sich v. a. um
            IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
            Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige
            Daten, die über eine Website generiert werden, handeln. Das externe
            Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren
            potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und
            im Interesse einer sicheren, schnellen und effizienten
            Bereitstellung unseres Online-Angebots durch einen professionellen
            Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
            Einwilligung abgefragt wurde, erfolgt die Verarbeitung
            ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25
            Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies
            oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B.
            Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
            ist jederzeit widerrufbar. Unser(e) Hoster wird bzw. werden Ihre
            Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner
            Leistungspflichten erforderlich ist und unsere Weisungen in Bezug
            auf diese Daten befolgen.
            <br />
            <br />
            Wir setzen folgende(n) Hoster ein:
            <br />
            <br />
            Vercel Inc. <br />
            340 S Lemon Ave #4133
            <br /> Walnut, California 91789
            <br /> United States
            <br /> ar@vercel.com
            <br />
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      <Footer />
    </div>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
